import * as React from "react";
import "./index.css";
import linkedin from "./assets/linkedin.png";
import twitter from "./assets/twitter.png";
import flickr from "./assets/flickr.png";
import deviantart from "./assets/deviantart.png";
import github from "./assets/github.png";

export function App() {
  return (
    <div className="main">
      <h1>Hijack.it</h1>
      <p>Welcome to my personal website (work in progress)</p>

      <p>(Pre)historical website: <a href="http://bugbuster.altervista.org">bugbuster.altervista.org</a></p>
      
      <p>
        <i>Giacomo Petronio</i><br/>
        <a href="https://www.linkedin.com/in/giacomopetronio/" target="_"><img src={linkedin} className="badge"></img></a>
        <a href="https://github.com/hijackit?tab=repositories" target="_"><img src={github} className="badge"></img></a>
        <a href="https://twitter.com/hijackit" target="_"><img src={twitter} className="badge"></img></a>
        <a href="https://www.deviantart.com/bugbusta" target="_"><img src={deviantart} className="badge"></img></a>
        <a href="https://www.flickr.com/photos/bugbusta" target="_"><img src={flickr} className="badge"></img></a>
      </p>
    </div>
  )
}